import React, { useState } from 'react';
import styles from './CustomMchsPopup.module.css';

function CustomMchsPopup({ isOpen, templateTags, hideCustomDialog }) {

    const handleClose = () => {
        if (hideCustomDialog) {
            hideCustomDialog(false);
        }
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.custom_dialog}>
                    <span className={styles.custom_dialog_close} onClick={handleClose}>
                        &times;
                    </span>
                    <p>
                        This checkmark is currently being used by template tags. Please
                        delete the following template tags to delete the checkmark:
                    </p>
                    <ul className={styles.template_tags_list}>
                        {templateTags.map((tag, index) => (
                            <li key={index}>
                                ||{tag.name}|| --> {tag.tags};
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default CustomMchsPopup;